import React, { createContext, useRef } from 'react';
import SimpleAlert, { IAlertProps } from '../components/simpleAlert';

interface IState {
  setError: (error: string, show?: boolean) => void;
  setSuccessText: (message: string, show?: boolean) => void;
  show: (delay?: number) => void;
}

export const AlertContext = createContext<IState>(null);

const AlertProvider = (props: any) => {
  const alertRef = useRef<IAlertProps>(null);
  return (
    <>
      <SimpleAlert ref={alertRef} />
      <AlertContext.Provider
        value={{
          setError: (error, show) => alertRef.current?.setError(error, show),
          setSuccessText: (message, show) => alertRef.current?.setSuccessText(message, show),
          show: (delay?: number) => alertRef.current?.show(delay),
        }}
        {...props}
      />
    </>
  );
};
export default AlertProvider;

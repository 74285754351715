import amp from 'amplitude-js';

let amplitude: typeof amp;

const getAmplitude = async () => {
  const key = process.env.NEXT_PUBLIC_AMPLITUDE_KEY || 'e76d121804f31be8f9f737bad68840c2';
  if (!amplitude) {
    amplitude = await import('amplitude-js');
    amplitude.getInstance().init(key);
  }
  return amplitude.getInstance();
};

export default getAmplitude;

import { ApolloProvider, useQuery } from '@apollo/client';
import App from 'next/app';
import Head from 'next/head';
import React, { useContext, useEffect, useState } from 'react';
import UserProvider, { UserContext } from '../contexts/userContext';
import AlertProvider from '../contexts/alertContext';
import theme from '../utils/theme';
import { Router, useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import 'tailwindcss/tailwind.css';
import '../styles/tailwind.css';
import dynamic from 'next/dynamic';
import firebase from '../utils/firebase';
import { FB_PIXEL_ID } from '../utils/fbPixel';
import client from '../utils/apolloClient';
import getAmplitude from '../utils/amplitude';
import { gql } from '@apollo/client/core';
import { GQLFeature } from 'townapps-models/graphql/types';
import { defaultSEODescription, defaultSEOTitle } from '../utils/seo';
import { imageDefaultShare } from '../utils/defaultImageShare';

const CookieConsent = dynamic(() => import('../components/cookieBanner'), { ssr: false });
const ThemeProvider = dynamic(() => import('@material-ui/styles/ThemeProvider'));
const Navbar = dynamic(() => import('../components/navbar'), { ssr: false });
const Footer = dynamic(() => import('../components/footer'), { ssr: false });
const Feedback = dynamic(() => import('../components/feedback'), { ssr: false });

const GET_FEATURES = gql`
  query getFeatures {
    features: getFeatures {
      feature
      platform
      description
      active
    }
  }
`;

const Content = ({ component, pageProps }) => {
  const Component = component;
  const router = useRouter();
  const userCtx = useContext(UserContext);
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);
  const { data } = useQuery<{ features: GQLFeature[] }>(GET_FEATURES);

  useEffect(() => {
    if (data?.features) {
      userCtx.setFeatures(data.features);
    }
  }, [data]);

  useEffect(() => {
    // Wait 10 seconds to load analytics, they're not needed at the beggining.
    setTimeout(() => {
      if (!firebase.client) {
        firebase.init();
      }
      import('react-facebook-pixel')
        .then(x => x.default)
        .then(async ReactPixel => {
          ReactPixel.init(FB_PIXEL_ID);
          const Cookies = (await import('js-cookie')).default;
          const consentCookie = Cookies.get('xploreConsentCookie');

          if (consentCookie === undefined) {
            setIsCookieBannerVisible(true);
          }

          if (consentCookie === 'yes' || consentCookie === undefined) {
            Router.events.on('routeChangeComplete', () => {
              ReactPixel.pageView();
            });
            ReactPixel.grantConsent();
          } else {
            ReactPixel.revokeConsent();
          }
        });
    }, 5000);
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5" />
        <title>xplore: Everything is local.</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,600&display=swap"
          rel="stylesheet"
        />
      </Head>
      <DefaultSeo
        title={defaultSEOTitle}
        description={defaultSEODescription}
        canonical={'https://xploreapp.io/'}
        openGraph={{
          images: [
            {
              url: imageDefaultShare,
              alt: 'Xplore',
            },
          ],
          title: defaultSEOTitle,
          type: 'website',
          locale: 'en_GB',
          url: 'https://xploreapp.io/',
          site_name: 'xplore',
          description: defaultSEODescription,
        }}
      />

      {!isCookieBannerVisible && <Feedback />}

      <ThemeProvider theme={theme}>
        <Navbar />
        <Component {...pageProps} />
        {router.pathname !== '/forgotpassword' && router.pathname !== '/vouchers' && router.pathname !== '/profile' && <Footer />}
        {isCookieBannerVisible && (
          <CookieConsent
            onAccept={async () => {
              setIsCookieBannerVisible(false);
              const Cookies = (await import('js-cookie')).default;
              const amplitude = await getAmplitude();
              amplitude.setOptOut(false);
              firebase.client.analytics().setAnalyticsCollectionEnabled(true);
              import('react-facebook-pixel')
                .then(x => x.default)
                .then(ReactPixel => {
                  ReactPixel.grantConsent();
                  Router.events.on('routeChangeComplete', () => {
                    ReactPixel.pageView();
                  });
                });
              Cookies.set('xploreConsentCookie', 'yes', { expires: 90 });
            }}
            onDecline={async () => {
              setIsCookieBannerVisible(false);
              const Cookies = (await import('js-cookie')).default;
              const amplitude = await getAmplitude();
              amplitude.setOptOut(true);
              firebase.client.analytics().setAnalyticsCollectionEnabled(false);

              import('react-facebook-pixel')
                .then(x => x.default)
                .then(ReactPixel => {
                  ReactPixel.revokeConsent();
                  Cookies.set('xploreConsentCookie', 'no', { expires: 90 });
                });
            }}
          />
        )}
      </ThemeProvider>
    </>
  );
};
class MyApp extends App<any, any> {
  public componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  public render() {
    const { Component, pageProps } = this.props;

    return (
      <ApolloProvider client={client}>
        <AlertProvider>
          <UserProvider>
            <Content component={Component} pageProps={pageProps} />
          </UserProvider>
        </AlertProvider>
      </ApolloProvider>
    );
  }
}
export default MyApp;

import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  white: '#F5F5F1',
  black: '#262626',
  green: '#0A8F7E',
  lightGreen: '#00BFA6',
  extraLightGreen: 'rgba(0, 191, 166, 0.05)',
  purple: '#3F3D56',
  blue: '#0F1F2E',
};

export const fonts = {
  light: 'Poppins, sans-serif',
  medium: 'Poppins, sans-serif',
  regular: 'Poppins, sans-serif',
  black: 'Poppins, sans-serif',
  bold: 'Poppins, sans-serif',
  thin: 'Poppins, sans-serif',
};

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.lightGreen,
    },
    secondary: {
      main: colors.black,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#ffffff',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid rgba(27,27,27,0.3) !important`,
        },
        '&:after': {
          borderBottom: `1px solid rgba(27,27,27,1) !important`,
        },
      },
    },
    MuiInputBase: {
      multiline: {
        paddingBottom: 0,
        paddingTop: 3,
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.black,
        fontFamily: fonts.bold,
      },
    },
    MuiButton: {
      contained: {
        borderRadius: 6,
      },
      label: {
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        color: 'white',
      },
    },
    MuiTableCell: {
      body: {
        fontFamily: fonts.medium,
        color: `${colors.black} !important`,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: fonts.regular,
      },
      body1: {
        fontFamily: fonts.regular,
        fontWeight: 500,
      },
      body2: {
        fontFamily: fonts.regular,
        fontWeight: 500,
      },
      h1: {
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        color: colors.lightGreen,
      },
      h2: {
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        color: colors.lightGreen,
      },
      h3: {
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        color: colors.lightGreen,
      },
      h4: {
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        color: colors.lightGreen,
      },
      h5: {
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        color: colors.lightGreen,
      },
      h6: {
        fontFamily: fonts.bold,
        fontWeight: 'bold',
        color: colors.lightGreen,
      },
    },
    MuiTextField: {
      root: {
        background: 'transparent',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: `${fonts.bold} !important`,
        fontWeight: 600,
        fontSize: 18,
        color: `rgba(27,27,27,0.6) !important`,
        background: 'white',
      },
    },
    MuiListItemText: {
      root: {
        color: '#777777',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
  },
});

export default theme;

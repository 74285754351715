import React, { createContext, useState } from 'react';
import { GQLCustomer, GQLFeature } from 'townapps-models/graphql/types';

interface IState {
  user: GQLCustomer;
  setUser: (user: GQLCustomer | null) => void;
  features: GQLFeature[];
  setFeatures: (features: GQLFeature[]) => void;
}

export const UserContext = createContext<IState | null>(null);

const UserProvider = (props: any) => {
  const [user, setUser] = useState<GQLCustomer | null>(null);
  const [features, setFeatures] = useState<GQLFeature[]>([]);

  return (
    <UserContext.Provider
      value={{
        features,
        setFeatures,
        user,
        setUser: async u => {
          setUser(u);
          if (u) {
            const Sentry = await import('@sentry/nextjs');
            Sentry.setUser({ email: u.email, id: u.id, username: u.name });
          }
        },
      }}
      {...props}
    />
  );
};
export default UserProvider;

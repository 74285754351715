const firebaseConfig = {
  apiKey: 'AIzaSyCqECxIBbtipkamBWCel4Q-EWBVW2llxZ0',
  authDomain: 'xplore-7fdc4.firebaseapp.com',
  databaseURL: 'https://xplore-7fdc4.firebaseio.com',
  projectId: 'xplore-7fdc4',
  storageBucket: 'xplore-7fdc4.appspot.com',
  messagingSenderId: '1002835088582',
  appId: '1:1002835088582:web:cc5feba808dd52a8d5b3c7',
  measurementId: 'G-PG51D0MRG2',
};

class Firebase {
  public initialised = false;
  public client: any;
  public async init() {
    const firebase = (await import('firebase/app')).default;
    await Promise.all([import('firebase/analytics')]);
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    this.client = firebase;
  }
}

const firebase = new Firebase();
export default firebase;

import React, { useEffect, useImperativeHandle, useState } from 'react';
import cn from 'classnames';

export type IAlertProps = {
  setError: (error: string, show?: boolean) => void;
  show: (delay?: number) => void;
  setSuccessText: (message: string, show?: boolean) => void;
};

const SimpleAlert = React.forwardRef<IAlertProps, {}>(({}, ref) => {
  const [error, setError] = useState('');
  const [successText, setSuccessText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [delay, setDelay] = useState(5000);

  useImperativeHandle(ref, () => ({
    setSuccessText: (message: string, show = true) => {
      setError(undefined);
      setSuccessText(message);
      setOpenAlert(show);
    },
    setError: (message: string, show = true) => {
      setSuccessText(undefined);
      setError(message);
      setOpenAlert(show);
    },
    show: (delay?: number) => {
      if (delay) setDelay(delay);
      setOpenAlert(true);
    },
  }));

  useEffect(() => {
    if (openAlert) {
      setTimeout(() => setOpenAlert(false), delay);
    }
  }, [openAlert]);
  return (
    <div
      onClick={() => setOpenAlert(false)}
      className={cn('fixed bottom-1 right-1 max-w-md  w-full transform-gpu translate-x-full transition-transform delay-200', {
        'translate-x-0': openAlert,
        '-right-1/2': !openAlert,
      })}
      style={{ zIndex: 99999 }}>
      <div
        className={cn('w-full min-h-16 flex flex-col rounded p-2', {
          'bg-green-400': !!successText,
          'bg-red-400': !!error,
        })}>
        <p
          className={cn('mb-1 font-bold', {
            'text-green-800': !!successText,
            'text-red-800': !!error,
          })}>
          {error ? 'Error' : 'Success'}
        </p>
        <p
          className={cn({
            'text-green-800': !!successText,
            'text-red-800': !!error,
          })}>
          {error || successText}
        </p>
      </div>
    </div>
  );
});

export default SimpleAlert;
